<template>
    <div class="select-project">
        <div class="title">
            <p class="txt">请选择预约项目</p>
        </div>
        <div class="project-list">
            <div class="project-item" v-for="(item,index) in reserveProjectList" :key="index" @click="goselectTime(item)">
                <img src="@/assets/img/project-item-icon.png" alt="">
                <p class="txt">
                    {{item.projectName}}
                </p>
                <van-icon class="icon" name="arrow" />
            </div>
        </div>
    </div>
</template>

<script>
// import calendar from "./components/calendar.vue";
import { ReserveProject } from "@/api/appointment";
export default {
    components:{
        // calendar
    },
    data () {
        return {
            reserveId:this.$route.query.reserveId,
            reserveProjectList:[]
        }
    },
    created () {
        this.getProject();
        document.title = "预约项目";
    },
    methods: {
        getProject(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            ReserveProject({
                reserveId:this.reserveId,
            }).then(res =>{
                toast.clear();
                console.log(res);
                if(res.data.errorCode == 0){
                    this.reserveProjectList = res.data.data
                }
            })
        },
        // 去预约时间页面
        goselectTime(item){
            this.$router.push({
                path:"/appointment/reserveTime",
                query:{
                    reserveId:this.reserveId,
                    projectId:item.projectId,
                    
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.select-project{
    height: 100%;
    display: flex;
    flex-direction: column;
    background: url("~@/assets/img/selec-project-icon.png") no-repeat, linear-gradient(360deg, #F2FBFF 0%, #76BDFF 100%);
    background-size: 5.08rem 4.2rem,100% 100%;
    background-position: 90% 0,0 0;
    .title{
        flex:  0  0 auto;
        height: 4.2rem;
        padding: 0 .6rem;
        display: flex;
        align-items: center;
        .txt{
            font-size: 0.96rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 1.34rem;
            text-shadow: 0 0.04rem 0.08rem #2379FF;
        }
    }
    .project-list{
        flex: 1;
        overflow: auto;
        padding: 0 0.6rem;
        .project-item{
            padding: 0.6rem;
            background: linear-gradient(180deg, #EBF7FF 0%, #FFFFFF 100%);
            border-radius: 0.24rem;
            border: 0.04rem solid #FFFFFF;
            margin-bottom: 0.6rem;
            display: flex;
            align-items: flex-start;
            padding-left: 2.28rem;
            position: relative;
            img{
                position: absolute;
                width: 0.84rem;
                top: -0.04rem;
                left: 0.7rem;
                height: 0.9rem;              
            }
            .txt{
                flex: 1;
                font-size: 0.64rem;
                line-height: 0.9rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #303133;
            }
            .icon{
                line-height: 0.9rem;
                flex: 0 0 auto;
                margin-left: 0.6rem;
                font-size: 0.6rem;
            }
        }
    }
}
</style>